<template>
    <v-container fluid style="padding-bottom: 100px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                             <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="department"
                                    :items="dept_names"
                                    item-value="dept_id"
                                    item-text="descr"
                                    label="Department"
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                             <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="approve"
                                    :items="approves"
                                    item-value="value"
                                    item-text="descr"
                                    label="Approved"
                                ></v-autocomplete>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                              <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="status"
                                    :items="statuses"
                                    item-value="value"
                                    item-text="status"
                                    label="Status"
                                ></v-autocomplete>
                            </v-col>
                           <!-- <v-col cols="2" xs="12" sm="5" md="2">
                                <v-dialog
                                    ref="dialog_from"
                                    v-model="modal"
                                    :return-value.sync="date_from"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date from"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attr"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date_from"
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog_from.save(date_from)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col> -->
                             <!-- <v-col cols="2" xs="12" sm="5" md="2">
                                <v-dialog
                                    ref="dialog"
                                    v-model="modal_to"
                                    :return-value.sync="date_to"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date_to"
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal_to = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(date_to)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                             <!-- <v-col cols="12" xs="12" sm="5" md="2">
                                <v-btn class="mr-2" color="error" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                 <!-- <v-card v-show="display == 'none' ? false: true"> -->
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                <template>
                                <v-btn v-if="$store.getters.UsernameUser === 'rio'"
                                    class="mr-2 mt-1"
                                    color="primary"
                                    elevation="2"
                                    rounded
                                    :disabled="disabledExport">
                                    <download-excel
                                         class="text"
                                        :fetch           = "exportExcel"
                                        :fields="headersColumn"
                                        :before-generate = "startDownload"
                                        :before-finish   = "finishDownload">
                                        Export Excel
                                    </download-excel>    
                                </v-btn>
                                   <v-btn v-else
                                   hidden="">
                                   </v-btn>
                            <v-btn v-if="$store.getters.UsernameUser === 'rio'"
                                    class="mr-2 mt-1 text-white"
                                    color="pink">
                                      <v-icon small class="mr-2" @click="uploadTenant()">
                                     mdi-upload
                                    </v-icon>
                                     <span>
                                        Upload File
                                     </span> 
                                </v-btn>
                                 <v-btn v-else
                                   hidden="">
                                   </v-btn>
                          
                                  <v-btn v-if="$store.getters.UsernameUser === 'rio'"
                                    class="mr-2 mt-1"
                                    color="success"
                                    elevation="2"
                                    :disabled="disabledExport">
                                    <v-icon small class="mr-2">
                                       mdi-eye
                                    </v-icon>
                                     <span>
                                        Show File
                                     </span>
                                </v-btn>
                                 <v-btn v-else
                                   hidden="">
                                   </v-btn>
                            <v-dialog v-model="dialog_item_tenant" max-width="800px">                
                                  <v-card>
                                      <v-progress-linear
                                              :active="loading_item_tenant"
                                              :indeterminate="loading_item_tenant"
                                              absolute
                                              bottom
                                              color="deep-purple accent-4"
                                      ></v-progress-linear>
                                      <v-card-title>Upload File Doc</v-card-title>
                                      <v-card-text>
                                          <v-container fluid>
                                            <v-row>
                                              <v-col cols="12">
                                                <v-text-field
                                                  v-model="doc_file"
                                                  label="Nama Doc"
                                              ></v-text-field>
                                              </v-col>
                                              <v-col cols="12">
                                                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                                              </v-col>
                                            </v-row>
                                          </v-container>
                                      </v-card-text>
                                      <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn
                                          class="mr-2"
                                          color="error"
                                          elevation="2"
                                          large
                                          rounded
                                          @click="saveFilePdf()"
                                          >Save</v-btn>
                                          <v-btn color="blue darken-1" text @click="close_item_tenant">Close</v-btn>
                                      </v-card-actions>
                                  </v-card>
                            </v-dialog>
                                </template>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="po_items"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    page-count="10"
                                    :search="searchItem"
                                    class="elevation-1"
                                >
                                <template v-slot:top>
                                        <v-text-field v-model="searchItem" label="Search... " class="mx-4" ></v-text-field>
                                    </template>
                                  <template v-slot:[`item.status`]="{ item }">
                                    <v-chip class="ma-2" color="yellow" text-color="black" v-if="item.stat === 'O'" >
                                        OPEN
                                    </v-chip>
                                    <v-chip class="ma-2" color="green" text-color="white" v-if="item.stat === 'C'">
                                        CLOSED
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.approve1`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-if="$store.getters.UsernameUser === user_approve1">
                                                <v-chip class="ma-2" color="success" v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_rjc == '1900-01-01 00:00:00.000'"  >
                                                    <v-icon center> 
                                                        mdi-check-circle
                                                    </v-icon>
                                                        Aprroved
                                                </v-chip>

                                                <v-chip class="ma-2" color="red" v-else-if="item.dt_rjc != '1900-01-01 00:00:00.000'" >
                                                    {{item.dt_rjc}}
                                                </v-chip>
                                                
                                                <v-chip class="ma-2" color="primary" v-else @click="setApprove1(item)" v-on="on">
                                                    <v-icon center> 
                                                        mdi-check-circle
                                                    </v-icon>
                                                    Confirmation
                                                </v-chip>
                                            </div>
                                            <div v-else>
                                                <v-chip class="ma-2 text-white" color="red" v-if="item.dt_aprv == '1900-01-01 00:00:00.000' && item.dt_rjc == '1900-01-01 00:00:00.000'" >
                                                    NOT APPROVED
                                                </v-chip>
                                                <v-chip class="ma-2" color="red" v-else-if="item.dt_rjc != '1900-01-01 00:00:00.000'" >
                                                    {{item.dt_rjc}}
                                                </v-chip>
                                                <v-chip class="ma-2" color="success" v-else>
                                                    APPROVED
                                                </v-chip>
                                            </div>
                                        </template>
                                        <span>Confirm</span>
                                    </v-tooltip>
                                </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="getPullDataDetail(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail PR Item</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>PR Item
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="search_detail"
                                                append-icon="mdi-magnify"
                                                label="Search PR ID"
                                                single-line
                                                @keyup.enter="search_temp"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_po_items"
                                            :items="po_itemsDetail"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogAgree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading4"
                            :disabled="loading4"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove1()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                                Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogAgree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn> -->
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 1 this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 2 this PR?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'MGS',
                disabled: false,
                href: '/admin/MGS',
                },
                {
                text: 'Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchase Request',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            dept_names:[],
            dept_name:'',
            descr:'',
            officeidlist:'',
            departments: [],
            department: '',
            approves: [
                {
                    descr: 'All',
                    value: ''
                },
                {
                    descr: 'Not Approved 1',
                    value: 'approve1'
                },
            ],
            approve: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                {
                    text: 'PR_ID',
                    align: 'start',
                    sortable: true,
                    value: 'pr_id',
                },
              
                { text: 'DEPARTMENT', value: 'dept_name' },
                { value: 'dt_pr', text: 'dt_pr'},
                { value: 'pic_name', text: 'Requester'},
                { text: 'STATUS', value: 'stat' },
                { text: 'MEMO', value: 'memo_txt' },
                { text: 'APPROVE 1',  value: 'approve1', align: 'center', sortable: false },
                { text: 'ACTION',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            loading_reject: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'PR_ID',
                    align: 'start',
                    sortable: true,
                    value: 'pr_id',
                },
                { value: 'descr', text: 'Article'},
                { value: 'unit_meas', text: 'Unit Meas.'},
                { value: 'qty', text: 'Qty'},
                { value: 'wgt', text: 'Weight'},
                { value: 'qty_po', text: 'Qty PO'},
                { value: 'wgt_po', text: 'Weight PO'},
                { value: 'qty_rcv', text: 'Qty Receive'},
                { value: 'wgt_rcv', text: 'Wgt Receive'},
            ],
            headersColumn: {

                'Pr-id': 'pr_id',
                'Departement': 'dept_name',
                'Memo': 'memo_txt',
                'Stat': 'stat',
                'Aprv_by' : 'aprv_by',
                'dt_aprv' : 'dt_aprv',
            },
            po_items: [],
            po_itemsDetail: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'MGS',
                appl_id : 'APPROVAL'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            dialog_item_tenant: false,
            loading_item_tenant: false,
            disabledExport:false,
            pagination: 1,
            pagination_item: 1,
            searchItemDt: '',
            doc_file: '',
            dateStartFormatted: '',
            dateEndFormatted: '',
            searchItem: ''
        }
    },
    mounted(){
        this.updateDepartment()
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            console.log(this.$store.getters.UsernameUser);
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPR1') {
                        this.user_approve1 = res.data.data[i]['var_value']
                        if (this.user_approve1 != null) {
                            this.approve = 'approve1'

                            // this.getPullData(1, 10)

                        }
                    }
                    if (this.approve != '') {
                        // this.getPullData(1, 10)
                    }

                }

            })
        },
        datetime_temp(val) {

            var dateStr

            dateStr = new Date(val).toISOString().substr(0, 10).replace(/-/g,"")

            return dateStr;

        },
        search(){
            // this.getPullData(1, 10, true)
        },
        uploadTenant(item){
          this.dialog_item_tenant = true
          this.tenant_detail = item
        },
        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        close() {
            this.dialog = false
        },
          close_item_tenant(){
          this.dialog_item_tenant = false
        },
        showItem(item){
            console.log(item);
            this.po_item = item
            // this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        async getPullDataDetail(item){
            this.po_itemsDetail = []
            this.dialog = true
            this.loading3 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/mgs/getPurchaseRequestResultDetail?pr_id=${item.pr_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading3 = false
                this.po_itemsDetail = res.data.result
            })
        },
        async getPullData(){
            this.loading = true
            if (this.date_from) {
                this.dateStartFormatted = new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.date_to) {
                this.dateEndFormatted = new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }
            await axios.get(`${process.env.VUE_APP_URL}/api/mgs/getPurchaseRequestResult?search=${this.$store.state.text ? this.$store.state.text : ''}&txtDepartment=${this.descr ? this.descr : ""}&txtApproved=${this.approve ? this.approve : ""}&txtStatus=${this.status ? this.status : ""}&start_date=${this.dateStartFormatted ? this.dateStartFormatted : ""}&end_date=${this.dateEndFormatted ? this.dateEndFormatted : ""}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.display = 'block'
                this.loading = false
                this.po_items = res.data.result
            })
        },
        handleFileUpload(){
          this.file = this.$refs.file.files[0];
          console.log(this.file);
        },
         saveFilePdf(){
            this.loading_item_tenant = true

            let formData = new FormData();
            formData.append('office_id', 1);
            formData.append('path_id', this.doc_file);
            formData.append('tenant_id', this.tenant_detail.tenant_id);
            formData.append('file', this.file);

            axios.post(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/file`, formData , 
            { 
              headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
            }).then(res => {
                this.loading_item_tenant = false
                this.dialog_item_tenant = false
                this.loading = false
                this.file = ''
                this.doc_file = ''
            }).catch(err => {
                this.loading_item_tenant = false
            })
        },

        async exportExcel(){
            //console.log("oke")
           const response = await axios.get(`${process.env.VUE_APP_URL}/api/mgs/getPurchaseRequestResult`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            //console.log("metu")
            //console.log(response.data.result)
            return response.data.result
            
        
        },
        startDownload(){
            // console.log("startDownload");
            this.loading = true
            this.disabledExport=true
        },
        finishDownload(){
            // console.log("finishDownload");
            this.loading = false
            this.disabledExport=false
        },
       
        updateDepartment(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/mgs/department`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.dept_names = res.data
               
            });
        },
        setApprove1(item){
            this.dialogAgree = true
            this.pr_item = item
        },
      
        setUnApprove(item){
            this.dialogDisagree = true
            this.pr_item = item
        },
      
        async submitApprove1(){
            this.loading4 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mgs/updateApprovalPR`, {
                'pr_id': this.pr_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };

                this.getPullData()

            }).catch(err => {
                this.loading4 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
       
        async submitUnApprove(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mgs/updateUnApprovalPR`, {
                'pr_id': this.pr_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 1",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },

        async submitReject(){
            this.loading_reject = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mgs/reject`, {
                'pr_id': this.pr_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading_reject = false
                this.dialogAgree = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Reject",
                    visible: true
                };
            }).catch(err => {
                this.loading_reject = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        }
        
    },
    watch: {
    }
}
</script>